export default function MainPage() {
    return(
 <div>
        {/* <hr> */}
            <center>
            <p>The <b>Apollonius Corp.</b> is a family office investment firm.</p>
            <table border="0" cellSpacing="0" width="90%">
                <tbody>
                <tr>
                    <td height="55" valign="top" width="50%">
                    <ul>
                        <li>
                        <a href="/message.html">A Message from the Apollonius Corp.</a> 
                        </li>
                    </ul>
                    </td>
                    <td height="45" valign="top" width="50%">
                        <ul>
                        <li>
                            <a href="/holdings.html">Apollonius Corp. Holdings</a> <br/><font 
                        size="1">Updated November 11, 2022</font>
                        </li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td height="45" valign="top" width="50%">
                    <ul>
                        <li>
                        <a href="/meeting.html">Annual Meeting Information</a>
                        <br/>
                        <font size="1">
                            Updated November 11, 2022
                            <br/>
                        </font>
                        </li>
                    </ul>
                    </td>
                    <td height="45" valign="top" width="50%">
                    <ul>
                        <li>
                        <a href="/governance.html">Corporate Governance</a>
                        <br/>
                        <font size="1">
                            Updated November 11, 2022
                            <br/>
                        </font>
                        </li>
                    </ul>
                    </td>
                </tr>
                </tbody>
            </table>
            </center>
    </div>
    )
}
