export default function MeetingPage() {
  return (
    <div>
        <div Style="margin-left: 120px; margin-right: 120px; margin-top:60px">
            <b>
            <p align="center "><font size="4">Shareholder Meeting Information</font></p>
            </b>
            <hr/>
            <ul><font size="3">
            <li>

            The 2023 Annual Meeting for Apollonius Corp. will be determined by Apollonius Corp. shareholders at a date and time of their choosing.
            </li>
            <br/><br/>
            <li>
            You are not invited, but are open to RSVP.
            </li>
            <br/>
            </font>
            </ul>
            <hr/>
            <font size="-1">The information on this website is presented in PDF format.  If you do not have Adobe Acrobat&reg; Reader&reg; software on your computer, 
            use the following link to go to Adobe's web site for a free download. &nbsp;&nbsp;&nbsp;<font COLOR="#ff0000"> <a
            HREF="http://www.adobe.com/">Adobe</a> </font></font>
            <hr/>
            <br/><br/>
        </div>
        </div>
  )
}