export default function HoldingsPage() {
    return(
        <div Style="margin: 60px;">
            <center>
            <table border="1" cellspacing="0" width="50%">
                <tbody>
                    <tr>
                        <td valign="top" width="50%">
                            <strong>Company/Holding</strong>
                        </td>
                        <td valign="top" width="50%">
                            <strong>Approximate Stake</strong>
                        </td>
                    </tr>
            <tr>
                <td valign="top" width="50%">
                        West Realm Shires Inc.
            </td>
            <td valign="top" width="50%">
                    52.99%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                        Paper Bird Inc.
                </td>
            <td valign="top" width="50%">
                    99.1%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                        Hilltop Technology Services LLC
            </td>
            <td valign="top" width="50%">
                    99.1%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                        Cedar Grove Technology Services, Ltd
                </td>
            <td valign="top" width="50%">
                    99.1%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                        Alameda Research Ventures LLC
                </td>
            <td valign="top" width="50%">
                    99.1%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                        Alameda Research LLC
                </td>
                <td valign="top" width="50%">
                    90%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                        Starry Night Capital
                </td>
            <td valign="top" width="50%">
                    65%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                    MicroStrategy
                </td>
                <td valign="top" width="50%">
                    49.998%
                </td>
            </tr>
            <tr>
                <td valign="top" width="50%">
                        West Ham FC
                </td>
                <td valign="top" width="50%">
                    0%
                </td>
            </tr>
            </tbody>
            </table>
            <p> Other, difficult-to-price assets include several warehouses of FTX merchandise, and certain stadium sponsorship agreements with regrettably harsh termination clauses.</p>
        </center>
        </div>
    )
}