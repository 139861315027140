
export default function Footer() {
    return (
        <div>
            <hr/>
            <p align="center">
        <font size="4">
            <a href="/" Style="margin: 20px;">Home</a>
        |
        <a href="/contact.html" Style="margin: 20px;">Contact</a>
        </font>
        </p>

        <dl><p align="center"><font size="2"><a href="legal.html">Legal Disclaimer</a> </font></p>
            <p align="center">
                Copyright &copy; 2021-2023 
                <br/>
            </p>
        </dl>
        </div> 
    )
}