// import logo from './logo.svg';
// import './App.css';

export default function LegalPage() {
  return (
    <div>
            <div Style="margin: 60px;">
            <center>
                <strong>
                <font size="+2">L</font><font size="+1">EGAL</font> <font size="+2">I</font><font size="+1">NFORMATION</font>
                </strong>
                <blockquote>
                    This website has been prepared solely for the purpose of providing
                    information about Apollonius Corp. and the services and products it
                    offers (or doesn't). This website has been compiled in good faith by Apollonius Corp.
                    However, no representation is made as to the completeness or accuracy of the
                    information it contains. In particular, you should be aware that this
                    information may be incomplete, may contain errors or may have become out of
                    date. Apollonius Corp. does not file any reports with the Securities and
                    Exchange Commission, because seriously, fuck that guy. Apollonius Corp makes no
                    commitment, and disclaims any duty, to update any of the reports or claims
                    made on this site. Apollonius Corp reserves the right to add, modify or delete any
                    information at this website at any time. This publication and any references
                    to products or services are provided "as is" without any warranty or implied
                    term of any kind.
                    </blockquote>
                    <blockquote>
                    <strong>Reproduction or distribution of any materials
                    obtained on this website or linking to this website without written
                    permission is encouraged.
                    </strong>
                    </blockquote>
                    <blockquote>
                    <strong>And more importantly: Code isn't law.</strong>
                </blockquote>

            <font size="-1">
            <br/>
            All rights reserved.
            </font>
            </center>
        </div>
    </div>
  );
}