export default function MessagePage() {
  return (
    <div>
            <div Style="margin: 120px; margin-top:90px; margin-bottom:90px;">
                <p><strong>A Message From Apollonius Corp. Inc.</strong></p>
                <br/>

                <p>Dear Reader,</p>
                <br/><br/>

                <p>As you know, Apollonius Corp. does not endorse or recommend any investments
                (least of all the ones in our portfolio). But we do have some recommendations
                for your personal finances that could save you a fair bit of money.
                Specifically, Apollonius Corp. suggests you consider two possibilities for your
                funds: FDIC-insured bank accounts, and lottery tickets.</p>

                <p>The FDIC was created in response to bank-runs during the Great Depression,
                and it remains, to this day, a bedrock in the American financial system. With
                insurance limits up to $250,000 for individual accounts (and $500,000 for joint
                accounts), the FDIC protects your funds with the full backing and faith of the
                United States Government. Granted, we have some degree of skepticism
                about the United States, but despite the uncertain political climate and their
                questionable economic policy, the FDIC remains a well-run organization worthy of
                our trust. You can't go wrong with a local bank account or credit union account,
                and these days you might even get a healthy interest rate!*</p>

                <p><font size="2">*Please note, your savings rate is unlikely to exceed actual
                    inflation, and the US Government has the absurdly invasive ability to violate
                    your privacy and freeze your funds through numerous questionably
                    constitutional laws and regulations.</font></p>

                <p>Every year, millions of people lose billions of dollars on their
                government-sanctioned lotteries. Why this form of state-sanctioned gambling is
                legal while many others are not confuses us, but frankly, it still beats losing
                all your money in a centralized shitcoin casino &mdash; at least the lottery
                proceeds ostensibly support public services rather than hollow PR campaigns
                about Effective Altruism. And where else are you going to put your money, the
                stock market?  Bonds? Real estate? LOL.</p>

                <p>Apollonius Corp. would like to further reiterate that the following
                financial advice was not, in fact, financial advice, and our lawyers assured us
                that by adding this caveat we have indemnified ourselves from any of your
                bad decisions. Incidentally we are looking for new lawyers, so if you happen to know any,
                please reach out.</p>
                <br />
                <p>With regards,<br /> Apollonius Corp.</p>

                </div>
    </div>
  );
}