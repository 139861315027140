export default function GovernancePage() {
    return (
        <div Style="margin: 60px;">
        <font>	
	<p>In addition to Apollonius Corp's breakthrough approach to distressed asset
	investing (we buy when asset prices are zero), we have a novel approach to
	corporate governance &mdash; despite our long history as a firm, we endeavor
	to remake ourselves frequently to keep our edge in the market (and because
	certain repeated bankruptcy proceedings obligate us to do so).</p>

	<p>Apollonius Corp. is a remote-first, employee-owned, limited but also full
	partnership, joint-venture LLC/GmbH/C-Corp, with several fully and partially
	owned subsidiaries. Our complex corporate structure serves two purposes.
	First, it keeps us agile enough to respond to a rapidly changing regulatory
	landscape. And second, it functions as a jobs program for lawyers, since part
	of our mission is to invest in positive-impact charity for society's
	benefit.</p>

	<p>Just to be perfectly clear: <strong>Apollonius Corp will never solicit
	funds from you. We will never ask for your information on a Google
	Spreadsheet.  Will never promise you an airdrop, NFT, and any other on-chain
	nonsense.</strong> Apollonius Corp is an insider cabal and you're not part of
	it. Not sure many of you would even survive the initiation (which involves a
	Bahamian Penthouse and a non-trivial amount of Vaseline and amphetamines).
	Sorry, and you're welcome.</p>

	<p>There are no less than thirty-six boards of directors, with various
	co-mingled directorships, leading to an aggregate of 74 distinct board members
	with intersecting influences, roles, and responsibilities. Each member is also
	an LP in the general fund, and Apollonius Corp has never accepted outside
	funding in its long and storied history.</p>

	<p>As for actual corporate governance, we leave tactical day-to-day investor
	relations to the executives of our various subsidiary funds, while there are
	quarterly remote check-ins that generally devolve into remote drinking matches.
	Incidentally, we hold in-person annual shareholder meetings near West Ham
	stadium in London &mdash; most of us are unfortunately banned from entering
	the actual stadium, so we tend to find a nice spot outside.</p>

	<p>Which brings us to the actual mission of Apollonius Corp. We are a
	non-traditional family office investment firm, with non-traditional origins, 
	and wouldn't you guess it, our aims are not...conventional. This provides the firm
	the agile and competitive edge necessary to compete in late stage capitalism. Yes we support 
	charities (including a significant donation to the Center for Ineffective Altruism
	every year) but our main purpose is to dramatically improve the welfare of all mankind,
	primarily by purchasing West Ham FC. Sure, selfishly it would mean they
	couldn't ban us from entering the stadium, but more importantly, we think
	there's great potential for West Ham FC to become a mecca for furthering the
	development of the human spirit. We would explain how, but honestly &mdash;
	and no offense here &mdash; we're not sure you'd really get it.</p>

	<p>Anyway, there's also a DAO, but no one is quite sure how it works or why we
	need it.</p>
</font>
</div>
    )
}