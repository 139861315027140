import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/header';
import Footer from './components/footer';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LegalPage from './pages/legal';
import MainPage from './pages/main';
import MessagePage from './pages/message';
import MeetingPage from './pages/meeting';
import HoldingsPage from './pages/holdings';
import GovernancePage from './pages/governance';
import ContactPage from './pages/contact';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
	<div className='App'>
    <BrowserRouter>
        <Header/>
		    <Routes>
          <Route path='/' element={<MainPage/>}/>
		    	<Route path='/legal.html' element={<LegalPage/>}/>
          <Route path='/message.html' element={<MessagePage/>}/>
          <Route path='/meeting.html' element={<MeetingPage/>}/>
          <Route path='/holdings.html' element={<HoldingsPage/>}/>
          <Route path='/governance.html' element={<GovernancePage/>}/>

          <Route path='/contact.html' element={<ContactPage/>}/>

		    	{/* {/* <Route path='/coming-soon' element={<Message/>}/> */}
		    </Routes>
		  </BrowserRouter>
      <Footer/>
      </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
