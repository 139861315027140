

export default function Header() {
    return (
        <div>
    <b>
    <p align="center">
    <font size="6">A</font>
    <font size="4">POLLONIUS </font>
    <font size="6">C</font>
    <font size="4">ORPORATION</font>
    {/* <font size="6">C</font><font size="4">APITAL </font> */}
    {/* <font size="2">INC </font> */}
    <br/>
    <font size="2">London N2 9ED, United Kingdom</font>
    <br/>
    <font>
    Official Home Page
    </font>
    </p>
    </b>    <a href="/">
            <p align="center">
            <img alt="" src="westham_coat_of_arms.png" height="128" />
            </p>
            </a>
            <p align="center">
            <font size="1">
            <i>Degeneranti, genus opprobrium</i>
            </font>
            </p>
            <hr/>
        </div> 
    )
}